<template>
  <ftx-dialog
    dialog="ChangeLocationConfirm"
    :position="getDialogPosition"
    :maximized="hasCartChange"
    :onHide="
      () => {
        this.$store.commit('location/SET_CHANGE_LOCATION_CART_DETAIL', null)
        this.$store.commit('location/SET_TO_LOCATION_N_FULFILLMENT', null)
      }
    "
  >
    <template v-slot:DialogContent>
      <q-card
        class="changeLocDialogue"
        :class="!hasCartChange && !isMobile ? 'radius' : ''"
        :style="!hasCartChange && 'width: 100%; max-width: 700px;'"
      >
        <div class="dialogHeader">
          <q-btn class="close" flat dense v-close-popup>
            <SvgIcon icon="close" />
          </q-btn>
          <div class="container">
            <h3 class="text-h5 text-center" v-text="title" />
            <!-- <h3 class="text-h5 text-center" v-else> Change Location </h3> -->
          </div>
        </div>
        <q-scroll-area
          class="changeLocDialogHeight"
          :thumb-style="scrollAreaThumbStyle"
          :bar-style="scrollAreaBarStyle"
          :visible="scrollAreaVisible"
          :style="
            hasCartChange
              ? 'max-width: 100%;'
              : isMobile
              ? 'height: 375px;'
              : 'height: 361px;'
          "
        >
          <div class="container" v-if="toLocation && currentLocationName">
            <div class="row q-col-gutter-lg">
              <div
                v-if="!isSelectedFulfillmentInactive"
                class="col-12"
                :class="hasCartChange ? 'col-md-6' : isMobile ? '' : 'q-pr-lg'"
              >
                <div
                  class="changeLocItem changeLocGreen"
                  v-show="showStayOnLabel"
                >
                  <div class="changeLocItemhd">
                    <div class="changeLocItemIcon">
                      <SvgIcon
                        color="primary"
                        :icon="selectedFulfillmentCodeName"
                      />
                    </div>
                    <div class="changeLocItemText">
                      <h4 v-html="stayOnLabel.label" />
                      <p
                        v-if="hasCartChange"
                        v-html="stayOnLabel.description"
                      />
                    </div>
                    <div class="changeLocItemBtn">
                      <q-btn
                        unelevated
                        no-caps
                        rounded
                        outline
                        color="white"
                        text-color="green"
                        label="Continue"
                        v-close-popup
                      />
                      <!-- @click="
                          $store.commit(
                            'location/SET_CHANGE_LOCATION_ADD_TO_CART',
                            null
                          )
                        " -->
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-12"
                :class="
                  hasCartChange
                    ? showStayOnLabel
                      ? 'col-md-6'
                      : 'col-md-12'
                    : isMobile
                    ? ''
                    : 'q-pr-lg'
                "
              >
                <div class="changeLocItem changeLocBlue">
                  <div class="changeLocItemhd">
                    <div class="changeLocItemIcon">
                      <SvgIcon
                        color="primary"
                        :icon="toLocationNFulfillment.fulfillment.codeName"
                      />
                    </div>
                    <div class="changeLocItemText">
                      <h4 v-html="switchToLabelNewCart.label" />
                      <p
                        v-if="hasCartChange"
                        v-html="switchToLabelNewCart.description"
                      />
                    </div>
                    <div class="changeLocItemBtn">
                      <q-btn
                        unelevated
                        no-caps
                        rounded
                        outline
                        color="white"
                        text-color="primary"
                        label="Continue"
                        @click="applyChangeLocation()"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12" v-if="hasCartChange">
                <div class="changeLocItem">
                  <div class="changeLocItemhd">
                    <div class="changeLocItemIcon">
                      <SvgIcon color="primary" :icon="toFulfillment.codeName" />
                    </div>
                    <div class="changeLocItemText">
                      <h4 v-html="switchToLabelMyCart.label" />
                      <p
                        v-if="hasCartChange"
                        v-html="switchToLabelMyCart.description"
                      />
                    </div>
                    <div class="changeLocItemBtn">
                      <q-btn
                        unelevated
                        no-caps
                        rounded
                        color="primaryOnBody"
                        label="Continue"
                        @click="applyChangeLocation(true)"
                      />
                    </div>
                  </div>
                  <div class="changeLocItemContent">
                    <div class="row q-col-gutter-lg">
                      <div
                        :class="`col-12 col-md-${
                          12 / localChangeLocationCartDetails.length
                        }`"
                        v-for="(
                          localChangeLocationCartDetail, index
                        ) in localChangeLocationCartDetails"
                        :key="index"
                      >
                        <h5
                          :class="localChangeLocationCartDetail.titleClass"
                          v-html="localChangeLocationCartDetail.title"
                        />

                        <q-list>
                          <q-item
                            v-for="(
                              availableProduct, index
                            ) in localChangeLocationCartDetail.products"
                            :key="index"
                            class="items-center"
                          >
                            <div class="col-12">
                              <h6>
                                <SvgIcon
                                  color="primary"
                                  :icon="availableProduct.codeName"
                                  class="q-mr-sm"
                                />
                                {{ availableProduct.displayName }}
                              </h6>
                              <div
                                v-for="(
                                  orderLineItem, index
                                ) in availableProduct.orderLineItems"
                                :key="`${orderLineItem.orderLineItemID}-${index}`"
                                class="row q-mb-md"
                                :class="{
                                  'cl-linked-product-items':
                                    orderLineItem.linkedProducts,
                                }"
                              >
                                <q-item-section avatar>
                                  <router-link
                                    :to="{
                                      name: 'Detail',
                                      params: {
                                        seoName: orderLineItem.seoName,
                                      },
                                    }"
                                    class="no-underline box-img"
                                  >
                                    <!-- <q-img
                                      :src="
                                        orderLineItem.image
                                          | defaultProductImage
                                      "
                                      width="50px"
                                    /> -->
                                    <q-img
                                      :src="
                                        orderLineItem.image
                                          | defaultProductImage
                                      "
                                      spinner-color="primary"
                                      spinner-size="16px"
                                      contain
                                      style="width: 50px; height: 50px"
                                    />
                                  </router-link>
                                </q-item-section>

                                <q-item-section>
                                  <q-item-label class="ellipsis">
                                    <router-link
                                      :to="{
                                        name: 'Detail',
                                        params: {
                                          seoName: orderLineItem.seoName,
                                        },
                                      }"
                                      class="no-underline"
                                      v-html="orderLineItem.productName"
                                    />
                                  </q-item-label>
                                  <q-item-label caption>
                                    {{ orderLineItem.qty }} x

                                    <strong
                                      class="text-primary q-mr-sm"
                                      v-if="
                                        localChangeLocationCartDetail.key ==
                                        'priceChangeProducts'
                                      "
                                    >
                                      {{
                                        orderLineItem.newFinalPrice | currency
                                      }}
                                      <span class="q-ml-sm text-strike">
                                        {{
                                          orderLineItem.oldFinalPrice | currency
                                        }}
                                      </span>
                                    </strong>
                                    <strong class="text-primary q-mr-sm" v-else>
                                      {{ orderLineItem.price | currency }}
                                    </strong>
                                  </q-item-label>
                                </q-item-section>

                                <!-- Linked Product -->
                                <div
                                  class="linked-product cl-linked-product"
                                  v-if="
                                    orderLineItem.linkedProducts &&
                                    orderLineItem.linkedProducts.length > 0
                                  "
                                >
                                  <q-item
                                    class="item-start"
                                    v-for="(
                                      linkedProduct, index
                                    ) in orderLineItem.linkedProducts"
                                    :key="`${linkedProduct.orderLineItemID}${index}`"
                                  >
                                    <q-item-section avatar>
                                      <q-img
                                        :src="
                                          linkedProduct.image
                                            | defaultProductImage
                                        "
                                        width="50px"
                                      />
                                    </q-item-section>

                                    <q-item-section>
                                      <q-item-label class="ellipsis">
                                        {{ linkedProduct.productName }}
                                      </q-item-label>
                                      <q-item-label caption>
                                        {{ linkedProduct.qty }} x

                                        <strong
                                          class="text-primary q-mr-sm"
                                          v-if="
                                            localChangeLocationCartDetail.key ==
                                            'priceChangeProducts'
                                          "
                                        >
                                          {{
                                            linkedProduct.newFinalPrice
                                              | currency
                                          }}
                                          <span class="q-ml-sm text-strike">
                                            {{
                                              linkedProduct.oldFinalPrice
                                                | currency
                                            }}
                                          </span>
                                        </strong>
                                        <strong
                                          class="text-primary q-mr-sm"
                                          v-else
                                        >
                                          {{ linkedProduct.price | currency }}
                                        </strong>
                                      </q-item-label>
                                    </q-item-section>
                                  </q-item>
                                </div>
                                <!-- End Linked Product -->
                              </div>
                            </div>
                            <!-- <q-item-section side top>
                          <q-btn
                            unelevated
                            no-caps
                            color="white"
                            text-color="red"
                          >
                            <SvgIcon size="1rem" icon="trash" />
                          </q-btn>
                        </q-item-section> -->
                          </q-item>
                        </q-list>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-scroll-area>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { reloadPage, hideShowFullSpinner } from 'src/utils'

export default {
  name: 'ChangeLocationConfirm',
  computed: {
    ...mapGetters('location', [
      'changeLocationCartDetail',
      'toLocationNFulfillment',
      'changeLocationAndAddToCart',
    ]),
    ...mapGetters('cart', ['currentCartNumber']),
    ...mapGetters('persisted', ['selectedZipCode']),
    ...mapGetters('common', ['isSelectedFulfillmentInactive']),
    toLocation() {
      return this.toLocationNFulfillment?.location
    },
    toFulfillment() {
      return this.toLocationNFulfillment?.fulfillment
    },
    hasCartChange() {
      return !!this.localChangeLocationCartDetails?.length
    },
    title() {
      let title = `How do you want your items?`

      if (this.hasCartChange && !this.isMobile) {
        title = `Looks like you already have a cart ${
          this.selectedFulfillmentCodeName == 'sp'
            ? 'at another Store'
            : this.selectedFulfillmentCodeName == 'sd'
            ? 'for Shipping Address'
            : 'for Delivery address'
        }! what would you like to do?`
      }

      return `${title}`
    },
    switchToLabelNewCart() {
      let fulfillmentName =
        this.toFulfillment?.fulfillmentName || this.toFulfillment?.displayName

      let displayLocationZip =
        this.toFulfillment?.codeName == 'sd'
          ? this.toLocationNFulfillment.selectedZipCode
          : this.toLocation.name

      return {
        label: `Switch to ${fulfillmentName} (<span class="text-primary">${displayLocationZip}</span>) ${
          this.localChangeLocationCartDetails.length
            ? 'and start a new cart!'
            : ''
        } `,
        description: `Your cart will get cleared and switch to ${fulfillmentName} "<span class="text-primary">${displayLocationZip}</span>"`,
      }
    },
    switchToLabelMyCart() {
      let fulfillmentName =
        this.toFulfillment?.fulfillmentName || this.toFulfillment?.displayName

      let displayLocationZip =
        this.toFulfillment?.codeName == 'sd'
          ? this.toLocationNFulfillment.selectedZipCode
          : this.toLocation.name

      return {
        label: `Switch to ${fulfillmentName} (<span class="text-primary">${displayLocationZip}</span>) ${
          this.localChangeLocationCartDetails.length
            ? 'and continue my cart!'
            : ''
        } `,
        description: `Switch to ${fulfillmentName} "<span class="text-primary">${displayLocationZip}</span>" and continue cart with below changes`,
      }
    },
    showStayOnLabel() {
      let result = true
      let fulfillmentCode = this.selectedFulfillment.codeName
      let fulfillment = this.businessFulfillments[fulfillmentCode]

      if (this.isSelectedFulfillmentInactive) result = false
      else if (!fulfillment) result = false
      else if (!fulfillment.isActive) result = false
      else if (fulfillment.isComingSoon) result = false
      return result
    },
    stayOnLabel() {
      let fulfillmentName =
        this.selectedFulfillment?.fulfillmentName ||
        this.selectedFulfillment?.displayName

      let displayLocationZip =
        this.selectedFulfillment?.codeName == 'sd'
          ? this.selectedZipCode
          : this.currentLocationName

      return {
        label: `Stay on ${fulfillmentName} (<span class="text-primary">${displayLocationZip}</span>)  ${
          this.localChangeLocationCartDetails.length
            ? 'and continue my cart!'
            : ''
        }`,
        description: `You will keep all items in your cart and stay on ${fulfillmentName} "<span class="text-primary">${displayLocationZip}</span>"`,
      }
    },
    localChangeLocationCartDetails() {
      var CartDetail = [],
        changeLocationCartDetail = this.changeLocationCartDetail

      if (changeLocationCartDetail?.availableProducts?.length)
        CartDetail.push({
          title: `${changeLocationCartDetail.availableProductsCount} Items Kept in Cart`,
          titleClass: '',
          key: 'availableProducts',
          products: changeLocationCartDetail.availableProducts,
        })

      if (changeLocationCartDetail?.priceChangeProducts?.length)
        CartDetail.push({
          title: `${changeLocationCartDetail.priceChangeProductsCount} Items with Changes`,
          titleClass: 'text-primary',
          key: 'priceChangeProducts',
          products: changeLocationCartDetail.priceChangeProducts,
        })

      if (changeLocationCartDetail?.notAvailableProducts?.length)
        CartDetail.push({
          title: `${changeLocationCartDetail.notAvailableProductsCount} Items Unavailable`,
          key: 'notAvailableProducts',
          titleClass: 'text-red',
          products: changeLocationCartDetail.notAvailableProducts,
        })

      return CartDetail
    },
    getDialogPosition() {
      return this.hasCartChange
        ? 'bottom'
        : this.isMobile
        ? 'bottom'
        : 'standard'
    },
  },
  methods: {
    async applyChangeLocation(applyChangeLocation = false) {
      hideShowFullSpinner(true)

      if (this.localChangeLocationCartDetails) {
        let fulfillmentID =
          this.toFulfillment.fulfillmentID ||
          this.toFulfillment.fulfillmentId ||
          this.toFulfillment.fulfillmentMethodID

        if (applyChangeLocation) {
          let requestObj = {
            locationID: this.toLocation.locationId,
            fulfillmentMethodID: fulfillmentID,
            zipCode:
              this.toFulfillment?.codeName === 'sd'
                ? this.toLocation.customerZip
                : this.toLocation.address?.zip,
          }
          let guestCartNumber = this.$store.getters['guest/guestCartNumber']
          if (!this.isLoggedIn && guestCartNumber)
            requestObj.orderNumber = guestCartNumber

          await this.$store.dispatch('location/ApplyChangeLocation', requestObj)
        } else await this.$store.dispatch('cart/emptyCart')
      }

      let payload = {
        location: this.toLocation,
        fulfillment: this.toFulfillment,
      }
      if (
        this.toFulfillment?.codeName == 'sd' ||
        this.toFulfillment?.codeName == 'scd'
      )
        payload.selectedZipCode = this.toLocationNFulfillment.selectedZipCode

      await this.$store.dispatch('persisted/setServiceLocation', payload)
      if (applyChangeLocation && this.changeLocationAndAddToCart)
        await this.$store.dispatch(
          'cart/addUpdateCartDetail',
          this.changeLocationAndAddToCart
        )
      // await this.$store.commit('location/SET_CHANGE_LOCATION_ADD_TO_CART', null)
      hideShowFullSpinner(false)
      reloadPage()
    },
  },
}
</script>

<style lang="scss">
.cl-linked-product-items {
  margin: 5px 0;
  border: 1px dashed #bdbdbd;
  border-radius: 5px !important;
  padding: 10px;
  .cl-linked-product {
    min-width: 100%;
    border: 3px solid rgba($primary, 0.1);
    border-radius: 7px;
    padding: 5px;
    margin-top: 10px;
  }
}
</style>
